import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Title } from '../../components/Title';
import ExpandMoreLessButton from '../../components/ExpandMoreLessButton';
import apiCallsService from '../../services/apiCalls.service';
import TablePage from '../../styledComponents/pages/TablePage';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TextField from "@mui/material/TextField";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Pagination from '@mui/material/Pagination';

import { timeframeConstants } from '../../constants/timeframes';

const MAX_DATE = dayjs();
const MIN_DATE = dayjs('2024-11-01');
const ROWS_PER_PAGE = 200;

export const AdAccountsCreativesCTR = () => {
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');

const [creativeName, setCreativeName] = useState('');
const [minLinkClicks, setMinLinkClicks] = useState('');

const [timeframe, setTimeframe] = useState('7d');

const [selectedFromDate, setSelectedFromDate] = useState(dayjs().subtract(8, 'day').format('YYYY-MM-DD'));
const [selectedToDate, setSelectedToDate] = useState(timeframeConstants.DEFAULT_TO_DATE);

const [tableData, setTableData] = useState([]);
const [filteredTableData, setFilteredTableData] = useState([]);

const [sortingBy, setSortingBy] = useState('clicks');
const [sortingDirection, setSortingDirection] = useState(false);

const [page, setPage] = useState(1);
const [totalCount, setTotalCount] = useState(0);

const getCreativesCTR = async (creativeName, selectedFromDate, selectedToDate, page) => {
	setLoading(true);
	setError('');

	try {
		const response = await apiCallsService.getCreativesCTR({
			creativeName: creativeName,
			minLinkClicks: minLinkClicks || 0,
			fromDate: selectedFromDate,
			toDate: selectedToDate,
			limit: ROWS_PER_PAGE,
			page: page
		});

		if (response.error) {
			setError(response.error);
			setTableData([]);
			setFilteredTableData([]);
			setTotalCount(0);

			return;
		}

		setTableData(response.data);
		setTotalCount(response.totalCount);
	} catch (err) {
		setError('Failed to fetch data');
		setTableData([]);
		setFilteredTableData([]);
		setTotalCount(0);
	} finally {
		setLoading(false);
	}
};

useEffect(() => {
	let result = [...tableData];

	if (sortingBy) {
		if (sortingDirection) {
			result = result.sort((a, b) => {
				if (sortingBy === 'ctr') {
					return parseFloat(a[sortingBy]) - parseFloat(b[sortingBy]);
				} else {
					return parseFloat(a[sortingBy]) - parseFloat(b[sortingBy]);
				}
			});
		} else {
			result = result.sort((a, b) => {
				if (sortingBy === 'ctr') {
					return parseFloat(b[sortingBy]) - parseFloat(a[sortingBy]);
				} else {
					return parseFloat(b[sortingBy]) - parseFloat(a[sortingBy]);
				}
			});
		}
	}

	setFilteredTableData(result);

}, [tableData, sortingDirection, sortingBy]);

useEffect(() => {
	getCreativesCTR(creativeName, selectedFromDate, selectedToDate, page);
}, [creativeName, minLinkClicks, timeframe, selectedFromDate, selectedToDate, page]);

const handleSortingBy = attribute => {
	if (attribute === sortingBy) {
		setSortingDirection(!sortingDirection);
	} else {
		setSortingBy(attribute);
		setSortingDirection(false);
	}
};

const stylesTableHead = attribute => ({
	color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.6)',
	cursor: 'pointer'
});

const handleDataByTimeframe = (timeframe, data) => {
	setTimeframe(timeframe);
	setSelectedFromDate(data.fromDate);
	setSelectedToDate(data.toDate);
	setPage(1);
};

const handleSelectedFromDate = value => {
	const newFromDate = dayjs(value);

	if (newFromDate.isBefore(MIN_DATE)) {
		setSelectedFromDate(MIN_DATE.format('YYYY-MM-DD'));
	} else if (newFromDate.isAfter(MAX_DATE)) {
		setSelectedFromDate(MAX_DATE.format('YYYY-MM-DD'));
	} else {
		setSelectedFromDate(newFromDate.format('YYYY-MM-DD'));
	}

	if (selectedToDate && newFromDate.isAfter(dayjs(selectedToDate))) {
		setSelectedToDate(newFromDate.add(1, 'day').format('YYYY-MM-DD'));
	}

	setTimeframe(null);
	setPage(1);
}

const handleSelectedToDate = value => {
	const newToDate = dayjs(value);

	if (newToDate.isAfter(MAX_DATE)) {
		setSelectedToDate(MAX_DATE.format('YYYY-MM-DD'));
	} else if (
		selectedFromDate &&
		newToDate.isAfter(dayjs(selectedFromDate).add(90, 'day'))
	) {
		setSelectedToDate(dayjs(selectedFromDate).add(90, 'day').format('YYYY-MM-DD'));
	} else {
		setSelectedToDate(newToDate.format('YYYY-MM-DD'));
	}

	setTimeframe(null);
	setPage(1);
}

const handlePageChange = (event, value) => {
	setPage(value);
};

const pageCount = Math.ceil(totalCount / ROWS_PER_PAGE);

	return (
		<>
			<Title title="Ad Accounts Creatives CTR" />

			<TablePage>
				<FormControl sx={{ mb: '20px' }}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<Stack direction="row" spacing={2} alignItems="center" mb={'20px'}>
					<DatePicker
						minDate={MIN_DATE}
						maxDate={MAX_DATE}
						value={dayjs(selectedFromDate)}
						onChange={(newValue) => handleSelectedFromDate(newValue)}
						format="YYYY-MM-DD"
					/>
					<span>-</span>
					<DatePicker
						minDate={
						selectedFromDate
							? dayjs(selectedFromDate).add(1, 'day')
							: MIN_DATE
						}
						maxDate={
						selectedFromDate
							? dayjs(selectedFromDate).add(90, 'day')
							: MAX_DATE
						}
						value={dayjs(selectedToDate)}
						onChange={(newValue) => handleSelectedToDate(newValue)}
						format="YYYY-MM-DD"
					/>
					</Stack>
				</LocalizationProvider>

					<ButtonGroup variant="outlined" sx={{ justifyContent: 'end' }}>
						{Object.entries(timeframeConstants.OFFER_TIMEFRAMES_MAP).map(([timeframeConstant, data], index) => {
						if (timeframeConstant === 'All') {
							return null;
						}

						return <Button onClick={() => handleDataByTimeframe(timeframeConstant, data)} variant={timeframe === timeframeConstant ? 'contained' : 'outlined'} key={index}>{data.title}</Button>
						})}
					</ButtonGroup>
				</FormControl>

				<FormControl fullWidth sx={{ mb: '20px' }}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: '10px' }}>
						<Typography gutterBottom variant="h5" component="div">
						Please enter Creative Name:
						</Typography>
					</Stack>

					<TextField 
						sx={{ mb: '20px'}}
						label="Creative Name"
						value={creativeName}
						onChange={(e) => {
							setCreativeName(e.target.value);
							setPage(1);
						}}
					/>

					<Box sx={{ mb: '10px' }}>
							<h4>Filters</h4>
					</Box>

					<Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: '20px' }}>
					<TextField
							label="Minimum Link Clicks"
							value={minLinkClicks}
							onChange={(e) => {
								setMinLinkClicks(e.target.value);
								setPage(1);
							}}
							type="number"
						/>
					</Stack>
				</FormControl>

				{loading ? (
				<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }} />
				) : error ? (
				<Alert severity="error">Error: {error}</Alert>
				) : !filteredTableData ? (
				<span>Choose Creative Name and Time period</span>
				) : (
				<>
					<TableContainer>
					<Table>
						<TableHead>
						<TableRow>
							<TableCell>Ad Account ID</TableCell>
							<TableCell>Ad Name</TableCell>
							<TableCell style={stylesTableHead('clicks')} onClick={() => handleSortingBy('linkClicks')}>Link Clicks<ExpandMoreLessButton enabled={sortingBy === 'linkClicks'} sortingDirection={sortingDirection} /></TableCell>
							<TableCell style={stylesTableHead('ctr')} onClick={() => handleSortingBy('ctr')}>CTR<ExpandMoreLessButton enabled={sortingBy === 'ctr'} sortingDirection={sortingDirection} /></TableCell>
						</TableRow>
						</TableHead>
						<TableBody>
						{filteredTableData.map((record, index) => (
							<TableRow key={index}>
							<TableCell>{record.adAccountID}</TableCell>
							<TableCell>{record.adName}</TableCell>
							<TableCell>{record.linkClicks}</TableCell>
							<TableCell>{record.ctr} %</TableCell>
							</TableRow>
						))}
						</TableBody>
					</Table>
					</TableContainer>
					<Pagination
						count={pageCount}
						page={page}
						onChange={handlePageChange}
						color="primary"
						sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
						/>
					</>
				)}
			</TablePage>
		</>
	);
};